import * as React from "react"
import Img from "gatsby-image"
import { Carousel } from "react-bootstrap"
import { Card } from "theme-ui"
import { GiCheckMark } from "@react-icons/all-files/gi/GiCheckMark"
import Box from "@material-ui/core/Box"

export default function SubHero({
  subheading,
  heading,
  text,
  sliderimage,
  features,
  videos,
  columns = 3,
  ...props
}) {
  return (
    <section
      py="2|3|2|2"
      position="relative"
      {...props}
      background="white"
      boxShadow="lg"
    >
      <div variant="container">
        <div
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          {heading && (
            <h2 variant="heading.h2" lineHeight="2">
              {heading}
            </h2>
          )}
          {text && (
            <p variant="text.lead" mt="2">
              {text}
            </p>
          )}

          {features && (
            <div display="grid" col={`1|2|${columns}`} gap="2|4" my="8|12">
              {features.map((feature, index) => (
                <Feature key={index} {...feature} />
              ))}
            </div>
          )}

          <div
            w="crousel||crousel"
            h="auto||crousel"
            top="0"
            right="0"
            rounded="lg||none"
            mt="4|6|8"
          >
            <Carousel>
              {videos === null
                ? sliderimage &&
                  sliderimage.map((simage, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <Img fluid={simage.fluid} />
                      </Carousel.Item>
                    )
                  })
                : null}
            </Carousel>
          </div>
          {videos && (
            <iframe
              display="block"
              src={videos}
              w="full"
              h="40|56|600"
              roundedTop="xl"
              overflow="hidden"
              paddingTop="10px"
            ></iframe>
          )}
        </div>
      </div>
    </section>
  )
}

export function Feature({ heading, ...props }) {
  return (
    <Box
      boxShadow={3}
      bgcolor="background.paper"
      m={1}
      p={1}
      style={{ width: "20rem", height: "7rem" }}
    >
      <div display="flex" alignItems="flex-start" {...props}>
        <div justifyContent="center" size="10">
          <GiCheckMark color="green" size="30" />
        </div>

        <div flex="1" ml="1">
          <h6 variant="text.subheading">{heading}</h6>
        </div>
      </div>
    </Box>
  )
}
