import * as React from "react"
import Flash from 'react-reveal/Flash';

export default function SubHeader({
  subheading,
  heading,
  text,
  buttons,
  ...props
}) {
  return (
    <section py="3|4|6|10" {...props}>
      <div variant="container">
        <div
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
        
 
         
          <Flash ssrFadeout>
          {heading && (
            <h1 variant="heading.h1" fontWeight="bolder" lineHeight="tight">
              {heading}
            </h1>
          )}
          </Flash>
          {text && (
            <p variant="text.lead" mt="6">
              {text}
            </p>
          )}
      {/*     {buttons} */}
        </div>
      </div>
    </section>
  )
}